/*============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/
@import "sass-lib";

@media all and (max-width: 1680px) {

.container {width: 1244px;; margin: 0 auto;}
.wrapper {width: 970px;}
h1 {font-size: 35px;}
p {font-size: 18px; line-height: 26px; margin-bottom: 20px;}

/* header
----------------------------------------------------------------------------------------------*/
header {
    
}

.sub_prod {
    .wrapper {width: 1200px;}
}
/* middle homepage
----------------------------------------------------------------------------------------------*/
.homepage {
    .homeSlide {
        .caption {
            position: absolute; top: 30%;
            h1 {
                font-size: 65px;
            }
        }
    }
}

.pg_title {font-size: 35px;}
.text {
    p {font-size: 18px; line-height: 26px;}
}

.sec_about {
    .about {padding-top: 60px;}
}
.sec_milestones {
    padding-bottom: 150px;
    .nav-direction {
        a {
            font-size: 26px; width: 58px; text-align: center;
            &:before {
                width: 55px; height: 62px; background-size: 110px;
            }
            &.prev {left: 30px;}
            &.next {right: 30px;}
        }
    }
}
.sec_meetOur {
    .box_img {
        width: 68%;
        .slider {
            padding-top: 70px;
            .txt_meet {
                height: 480px; border: 2px solid #ccc;
                padding: 0 40px;
            }
        }  
    }
    .img {width: 50%;}
    .right_meet {
        padding-top: 70px; width: 32%;
        p {padding-left: 10px;}
    }
}
.sec_testimoni {
    .pg_title {margin-bottom: 50px;}
    .block_testi {
        .textTestimoni {
            width: 42%;
            .slide {
                .txt_testimoni {margin-top: 40%;}
            }
        }
        .Imgtestimoni {
            .img {
                img {width: 100%;}
            }
        }
    }
}




.prod_pg, .news_pg, .poject_pg {
    .wrapper {width: 1200px;}
}

.detail_prod {
    .dec_prod {
        ul {
            li {font-size: 20px; margin-bottom: 15px; line-height: 24px;}
        }
    }
}
.decription {
    p {font-size: 18px; line-height: 26px;}
}
.dot {font-size: 26px;}
.text_thanks {
    h1 {font-size: 70px;}
    p {font-size: 22px; line-height: 30px; }
    &.karir {
        p {font-size: 26px;}
    }
}
.left_project {
    margin-left: 115px;
    .project_content {width: 970px;}
}
.slide_recomend {
    .slide {width: 700px;}
}
.SlideText {width: 700px;}
.listCareer {
    width: 100%;
    .list {
        div {
            &.submit {
                .btn {width: 220px; display: inline-block; line-height: 50px;}
            }
        } 
    }
}
.img_side_carrer {width: 22%;}
.contact_sec {
    h2 {font-size: 26px;}
    .list_location {
        .location {
            p {font-size: 16px; line-height: 22px;}
        }
    }
    .contact_form {
        .formContact {
            p {font-size: 18px; line-height: 26px;}
        }
        .img.right {margin: 9% 0 0;}
    }
}

/* footer
----------------------------------------------------------------------------------------------*/
/*footer {
    padding-top: 40px;
    .foot {
        margin: 13px 0;
        &.left {
            ul {
                li {
                    margin: 0 3px;
                    a {width: 32px; height: 32px; background-size: 22px !important;}
                }
            }
        }
        &.center {
            position: absolute; left: 0; right: 0; top: -15px; width: 100%; margin: 0;
            ul {
                li {
                    margin: 0;
                    a {padding-right: 10px; margin-right: 8px;}
                    &:last-child {
                        a {margin-right: 0;}
                    }
                }
            }
        }
    }
}*/



}